angular.module('app.controllers').controller('MailCtrl', ['$scope', '$rootScope', 'APIMailer', 'UI'].append(function(self, root, APIMailer, UI) {
  var init;
  
  // Initialize controller - clean up any existing validation state
  init = function() {
    // Use a small timeout to ensure the DOM is ready
    return setTimeout(function() {
      // Find all elements with is-invalid class and remove it
      document.querySelectorAll('.is-invalid').forEach(function(el) {
        return el.classList.remove('is-invalid');
      });
      
      // Add input event listeners to all form elements to clear validation errors on input
      return document.querySelectorAll('[required]').forEach(function(el) {
        var eventType;
        // For checkboxes, use change event instead of input
        eventType = el.type === 'checkbox' ? 'change' : 'input';
        return el.addEventListener(eventType, function() {
          // Remove invalid class when user starts typing/changing the field
          return el.classList.remove('is-invalid');
        });
      });
    }, 0);
  };
  
  // Run initialization
  init();
  return self.sendMail = function(subject, ev, toPress = false) {
    var checkboxLabel, element, emailPattern, field, findLabelText, firstInvalidElement, formData, formElement, formElements, i, isValid, j, labelText, len, len1, optionText, requiredFields, selectedOption;
    ev.preventDefault();
    if (toPress === true) {
      toPress = 'press';
    } else {
      toPress = '';
    }
    // Get the form element 
    formElement = ev.target;
    
    // Validate form before processing
    isValid = true;
    
    // Reset previous validation state - clear all previous validation classes
    formElement.querySelectorAll('.is-invalid').forEach(function(el) {
      return el.classList.remove('is-invalid');
    });
    
    // Check required fields individually
    requiredFields = formElement.querySelectorAll('[required]');
    for (i = 0, len = requiredFields.length; i < len; i++) {
      field = requiredFields[i];
      if (field.type === 'checkbox' && !field.checked) {
        // Mark checkbox as invalid
        field.classList.add('is-invalid');
        isValid = false;
      } else if (field.value.trim() === '') {
        // Mark field as invalid
        field.classList.add('is-invalid');
        isValid = false;
      // Add email validation if needed
      } else if (field.type === 'email' && field.value.trim() !== '') {
        // Simple email validation
        emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(field.value.trim())) {
          field.classList.add('is-invalid');
          isValid = false;
        }
      }
    }
    if (!isValid) {
      // Find the first invalid element and scroll to it
      firstInvalidElement = formElement.querySelector('.is-invalid');
      if (firstInvalidElement) {
        // Scroll the element into view with smooth behavior
        firstInvalidElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        firstInvalidElement.focus();
      }
      return false;
    }
    
    // Create an array to store form data
    formData = [];
    
    // Collect all input, select, and textarea elements from the form
    formElements = formElement.querySelectorAll('input, select, textarea');
    
    // Helper function to find label text for an element
    findLabelText = function(element) {
      var label;
      // Try to find label by for attribute matching element's id
      if (element.id) {
        label = formElement.querySelector(`label[for='${element.id}']`);
        if (label) {
          // Extract text and remove asterisk if present
          return label.textContent.trim().replace('*', '');
        }
      }
      
      // If no label found, fallback to the input name or id
      return element.name || element.id;
    };

    // Loop through each form element and add its value to the formData object
    for (j = 0, len1 = formElements.length; j < len1; j++) {
      element = formElements[j];
      // Skip submit buttons and elements without a name attribute
      if (element.type !== 'submit' && (element.name || element.id)) {
        // Get label text to use as key
        labelText = findLabelText(element);
        
        // Handle different element types
        if (element.type === 'checkbox') {
          // For checkboxes, try to find the associated text
          checkboxLabel = formElement.querySelector(`label[for='${element.id}'] .checkmark__box--text`);
          if (checkboxLabel) {
            labelText = checkboxLabel.textContent.trim().replace('*', '');
          }
          formData.push({
            labelText: labelText,
            value: element.checked
          });
        // Handle select elements to use the selected option's text
        } else if (element.tagName.toLowerCase() === 'select') {
          // Get the selected option
          selectedOption = element.options[element.selectedIndex];
          if (selectedOption) {
            // Use the option's text content instead of its value
            optionText = selectedOption.textContent.trim();
            formData.push({
              labelText: labelText,
              value: optionText
            });
          } else {
            // If no option is selected, use an empty string
            formData.push({
              labelText: labelText,
              value: ''
            });
          }
        } else {
          formData.push({
            labelText: labelText,
            value: element.value
          });
        }
      }
    }
    (new APIMailer(formData)).$save({
      subject: subject,
      to: toPress
    }, function(data) {
      UI.notify(true, data.message);
      return formElement.reset();
    }, function(response) {
      return UI.notify(false, response.data.error);
    });
    return false;
  };
}));
