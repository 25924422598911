angular.module('app.controllers').controller 'MailCtrl', [
  '$scope', '$rootScope', 'APIMailer', 'UI'
].append (self, root, APIMailer, UI) ->
      
    # Initialize controller - clean up any existing validation state
    init = ->
      # Use a small timeout to ensure the DOM is ready
      setTimeout ->
        # Find all elements with is-invalid class and remove it
        document.querySelectorAll('.is-invalid').forEach (el) ->
          el.classList.remove('is-invalid')
        
        # Add input event listeners to all form elements to clear validation errors on input
        document.querySelectorAll('[required]').forEach (el) ->
          # For checkboxes, use change event instead of input
          eventType = if el.type == 'checkbox' then 'change' else 'input'
          el.addEventListener eventType, ->
            # Remove invalid class when user starts typing/changing the field
            el.classList.remove('is-invalid')
      , 0
    
    # Run initialization
    init()
      
    self.sendMail = (subject, ev, toPress = false) ->
      ev.preventDefault()
      
      if(toPress == true) then toPress = 'press' else toPress = ''

      # Get the form element 
      formElement = ev.target
      
      # Validate form before processing
      isValid = true
      
      # Reset previous validation state - clear all previous validation classes
      formElement.querySelectorAll('.is-invalid').forEach (el) ->
        el.classList.remove('is-invalid')
      
      # Check required fields individually
      requiredFields = formElement.querySelectorAll('[required]')
      for field in requiredFields
        if field.type == 'checkbox' and !field.checked
          # Mark checkbox as invalid
          field.classList.add('is-invalid')
          isValid = false
        else if (field.value.trim() == '')
          # Mark field as invalid
          field.classList.add('is-invalid')
          isValid = false
        # Add email validation if needed
        else if field.type == 'email' and field.value.trim() != ''
          # Simple email validation
          emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          if !emailPattern.test(field.value.trim())
            field.classList.add('is-invalid')
            isValid = false
      
      # Exit if validation failed
      if !isValid
        # Find the first invalid element and scroll to it
        firstInvalidElement = formElement.querySelector('.is-invalid')
        if firstInvalidElement
          # Scroll the element into view with smooth behavior
          firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
          firstInvalidElement.focus()
        return false
      
      # Create an array to store form data
      formData = []
      
      # Collect all input, select, and textarea elements from the form
      formElements = formElement.querySelectorAll('input, select, textarea')
      
      # Helper function to find label text for an element
      findLabelText = (element) ->
        # Try to find label by for attribute matching element's id
        if element.id
          label = formElement.querySelector("label[for='#{element.id}']")
          if label
            # Extract text and remove asterisk if present
            return label.textContent.trim().replace('*', '')
        
        # If no label found, fallback to the input name or id
        element.name || element.id
      
      # Loop through each form element and add its value to the formData object
      for element in formElements
        # Skip submit buttons and elements without a name attribute
        if element.type != 'submit' and (element.name or element.id)
          # Get label text to use as key
          labelText = findLabelText(element)
          
          # Handle different element types
          if element.type == 'checkbox'
            # For checkboxes, try to find the associated text
            checkboxLabel = formElement.querySelector("label[for='#{element.id}'] .checkmark__box--text")
            if checkboxLabel
              labelText = checkboxLabel.textContent.trim().replace('*', '')
            formData.push({ labelText: labelText, value: element.checked })
          # Handle select elements to use the selected option's text
          else if element.tagName.toLowerCase() == 'select'
            # Get the selected option
            selectedOption = element.options[element.selectedIndex]
            if selectedOption
              # Use the option's text content instead of its value
              optionText = selectedOption.textContent.trim()
              formData.push({ labelText: labelText, value: optionText })
            else
              # If no option is selected, use an empty string
              formData.push({ labelText: labelText, value: '' })
          else
            formData.push({ labelText: labelText, value: element.value })
      
      (new APIMailer formData).$save {subject: subject, to: toPress }, (data) ->
        UI.notify true, data.message
        formElement.reset()
      , (response) ->
        UI.notify false, response.data.error
      
      return false
      
